import React, { FormEventHandler } from 'react';
import ReactDOM from 'react-dom';

import { useModal, Modal } from 'react-morphing-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import 'react-morphing-modal/dist/ReactMorphingModal.css';
import 'styles/morphing-modal/FullscreenModal.css';

import * as S from './FullscreenModalStyles';

//#region TYPES
export type ModalProps = import('react-morphing-modal/dist/Modal').ModalProps;

export type FullscreenModalProps<P extends {} = {}> = {
  bodyRenderer?: React.ComponentType<P> | React.ElementType;
  children: React.ReactNode;
  closable?: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  modalProps: ModalProps;
  formId?: string;
  onClose?: () => void;
  onSubmit?: FormEventHandler;
  // withoutBorder?: boolean;
};
//#endregion

const FullscreenModal: React.VFC<FullscreenModalProps> = props => {
  const {
    bodyRenderer: Renderer = 'form',
    closable = true,
    header,
    children,
    footer,
    modalProps,
    formId,
    onClose,
    // withoutBorder,
    ...restProps
  } = props;

  function handleCloseClick() {
    if (onClose) onClose();
    modalProps.close();
  }

  return ReactDOM.createPortal(
    <Modal {...modalProps} padding={false} closeButton={false}>
      <S.Container>
        <S.Header
        // style={{
        //   position: withoutBorder ? 'fixed' : 'inherit',
        //   background: withoutBorder ? '#fff' : 'inherit',
        //   width: withoutBorder ? '100%' : 'inherit',
        //   zIndex: withoutBorder ? '2' : 'inherit',
        // }}
        >
          <div>{header}</div>
          {closable && (
            <button type="button" onClick={handleCloseClick}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </S.Header>
        <Renderer {...restProps} id={formId}>
          <S.Body>{children}</S.Body>
          {footer && <S.Footer>{footer}</S.Footer>}
        </Renderer>
      </S.Container>
    </Modal>,
    document.getElementById('root')!
  );
};

export default FullscreenModal;

export { useModal };
