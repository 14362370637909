import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'hooks';
import { useDispatch } from 'store';

import { NotificationMessageType } from 'lib/constants';
import { getCount } from 'store/actions/notificationsPanelActions';
import { authenticateBusiness, logout } from 'store/actions/authActions';
import {
  getScheduledCount,
  pendingPostDeleted,
  updateSinglePost,
} from 'store/actions/postsActions';
import { pendingPostDeleted as uiPendingPostDeleted } from 'store/actions/uiActions';
// import { setUserConnected, setUserDisconnected } from 'store/actions/usersActions';
import { removeBirthday } from 'store/actions/birthdayActions';
import {
  renderToast,
  TYPE_POST_SCHEDULED,
  TYPE_VIDEO_READY,
} from 'components/layout/Common/CustomNotification';
import { makeSubscription } from './Messaging';

export interface UsePushNotificationArgs {
  onNewAppVersion: () => void;
}

interface PostMessage {
  id: string;
}

export function usePushNotification({ onNewAppVersion }: UsePushNotificationArgs) {
  const { t } = useTranslation();
  const { userId } = useAuth();
  const dispatch = useDispatch();

  const fetchNotificationCount = useCallback(() => {
    dispatch(getCount(userId));
  }, [dispatch, userId]);

  const fetchScheduleCount = useCallback(() => {
    dispatch(getScheduledCount());
  }, [dispatch]);

  const deactivatedUserHandler = useCallback(
    (strUserId: string) => {
      const deactivatedUserId = parseInt(strUserId, 10);
      console.log('no deactivatedUserHandler');

      if (deactivatedUserId === userId) dispatch(logout());

      dispatch(removeBirthday(deactivatedUserId));
    },
    [dispatch, userId]
  );

  // useEffect(() => {
  //   (async function () {
  //     const deviceToken = await requestToken();
  //     if (deviceToken && userId) {
  //       dispatch(deviceTokenReceived(deviceToken));

  //       await account.checkDevice({
  //         deviceToken,
  //         platform: DEVICE_PLATFORMS.webBrowser,
  //         userId,
  //       });
  //     }
  //   })();
  // }, [dispatch, userId]);

  useEffect(() => {
    const unsubNewPosts = makeSubscription(NotificationMessageType.NewPosts, () =>
      fetchNotificationCount()
    );

    const unsubNewComments = makeSubscription(NotificationMessageType.NewComments, () =>
      fetchNotificationCount()
    );

    const unsubAddedToPage = makeSubscription(NotificationMessageType.AddedToPage, () =>
      fetchNotificationCount()
    );

    const unsubAddedToGroup = makeSubscription(NotificationMessageType.AddedToGroup, () =>
      fetchNotificationCount()
    );

    const unsubNewLikes = makeSubscription(NotificationMessageType.NewLikes, () =>
      fetchNotificationCount()
    );

    const unsubNewBirthdays = makeSubscription(NotificationMessageType.NewBirthdays, () =>
      fetchNotificationCount()
    );

    const unsubVideoFinished = makeSubscription(NotificationMessageType.VideoFinished, () => {
      fetchNotificationCount();
      fetchScheduleCount();

      renderToast({
        type: TYPE_VIDEO_READY,
        title: t('post.videoReadyTitle'),
        body: t('post.videoReady'),
      });
    });

    const unsubEditPostReady = makeSubscription<PostMessage>(
      NotificationMessageType.EditPostReady,
      (_, data) => {
        const postId = parseInt(data!.id);
        fetchNotificationCount();

        dispatch(updateSinglePost(postId)).then(() =>
          renderToast({
            type: TYPE_VIDEO_READY,
            title: t('post.videoReadyTitle'),
            body: t('post.videoReady'),
          })
        );
      }
    );

    const unsubScheduleExecuted = makeSubscription<PostMessage>(
      NotificationMessageType.ScheduleExecuted,
      (_, data) => {
        const postId = parseInt(data!.id);
        fetchNotificationCount();
        fetchScheduleCount();
        dispatch(pendingPostDeleted(postId));
        dispatch(uiPendingPostDeleted(postId));

        renderToast({
          type: TYPE_POST_SCHEDULED,
          title: t('notification.post.scheduleReadyBody'),
        });
      }
    );

    const unsubDeactivatedUser = makeSubscription(
      NotificationMessageType.DeactivatedUser,
      (_, data) => deactivatedUserHandler(data)
    );

    return () => {
      unsubNewPosts();
      unsubNewComments();
      unsubAddedToPage();
      unsubAddedToGroup();
      unsubNewLikes();
      unsubNewBirthdays();
      unsubVideoFinished();
      unsubEditPostReady();
      unsubScheduleExecuted();
      unsubDeactivatedUser();
    };
  }, [deactivatedUserHandler, dispatch, fetchNotificationCount, fetchScheduleCount, t]);

  useEffect(() => {
    // const unsubUserConnected = makeSubscription(
    //   NotificationMessageType.UserConnected,
    //   (_, data) => {
    //     const userId = parseInt(data);
    //     dispatch(setUserConnected(userId));
    //   }
    // );

    // const unsubUserDisconnected = makeSubscription(
    //   NotificationMessageType.UserDisconnected,
    //   (_, data) => {
    //     const userId = parseInt(data);
    //     dispatch(setUserDisconnected(userId));
    //   }
    // );

    const unsubUpdatedPermission = makeSubscription(
      NotificationMessageType.UpdatedPermissions,
      () => dispatch(authenticateBusiness())
    );

    const unsubDeactivatedNetwork = makeSubscription(
      NotificationMessageType.DeactivatedNetwork,
      () => dispatch(logout())
    );

    return () => {
      // unsubUserConnected();
      // unsubUserDisconnected();
      unsubUpdatedPermission();
      unsubDeactivatedNetwork();
    };
  }, [dispatch]);

  useEffect(() => {
    const unsubNewAppVersion = makeSubscription(NotificationMessageType.NewAppVersion, () =>
      onNewAppVersion()
    );

    // console.log('onNewAppVersion');

    return () => {
      unsubNewAppVersion();
    };
  }, [onNewAppVersion]);
}
