import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const icon = css`
  margin-top: 0;
  margin-right: 4px;
`;
export const linkButton = (theme: Theme) => css`
  &.ant-btn-link {
    padding: 0;
    color: ${theme.colors.gray9};
  }
`;

export const menuItemStyle = (theme: Theme) => css`
  &.ant-dropdown-menu-item {
    padding: 8px 16px;
  }
`;

export const iconExactSize = css`
  width: 15px;
  height: 15px;
  svg {
    width: 15px;
    height: 15px;
  }
`;

export const meatballMenu = (theme: Theme) => css`
  height: 20px;
  padding: 0;

  ${theme.typography.fontLarge('20px')};
  ${linkButton(theme)};

  &.ant-btn-link :hover,
  &.ant-btn-link :focus {
    color: initial;
  }
`;

export const PostHeaderStyled = styled.div`
  display: flex;
  margin-bottom: 16px;
`;
export const InfoWrapper = styled.div`
  flex: 1;
  margin-left: 12px;
  color: ${({ theme }) => theme.colors.gray9};
`;
export const HeaderTitle = styled.h4`
  display: inline-block;
  margin: 0;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};

  ${({ theme }) => theme.typography.fontSmall('20px')}
`;
export const PostHeaderInfo = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.gray9};

  ${({ theme }) => theme.typography.fontSmall('20px')};

  a {
    color: ${({ theme }) => theme.colors.gray9};
  }
`;
export const Conjunction = styled.span`
  margin: 0 4px;

  ${({ theme }) => theme.typography.fontSmall('20px')};
`;
export const Edited = styled.span`
  margin-left: 4px;
  text-transform: lowercase;
`;
export const ShareType = styled.span`
  padding: 0 4px;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Divider = styled.div`
  margin: 0 8px;
`;
export const MenuItem = styled.span`
  margin-left: 8px;
  ${({ theme }) => theme.typography.fontNormal()};
`;
export const SaveMenuItem = styled.div`
  display: flex;
  align-items: center;

  .description {
    flex: 1;
    margin-left: 16px;
  }

  .hint {
    color: ${({ theme }) => theme.colors.gray7};
    ${({ theme }) => theme.typography.fontSmall()};
  }
`;
export const ToastBody = styled.div`
  display: flex;
  align-items: center;

  .message {
    flex: 1;
    margin-left: 11px;
  }
`;
export const BookmarksLink = styled(Link)`
  align-self: flex-end;
  margin-left: 16px;
  font-weight: 800;
  color: #fff;

  :hover {
    color: #fafafa;
  }
`;
