const pt = {
  currentVersion: 'Versão atual',
  availableApp: 'Aplicativo disponível',
  backHome: 'Voltar para a home',
  company: 'Empresa',
  done: 'Concluído',
  check: 'Ver',
  filters: 'Filtros',
  like: 'Curtir',
  noData: 'Não há dados',
  requestPermission: 'Precisamos de sua permissão para exibir notificações',
  responsive:
    'A versão responsiva ainda não está pronta. Para uma experiência melhor nos dispositivos móveis, baixe os aplicativos clicando nos links abaixo.',
  search: 'Buscar',
  select: 'Selecione...',
  tasks: 'Tarefas',
  unsupportedAudio: 'Seu browser não suporta recursos de audio',
  update: 'Atualizar',
  newSystemVersion: 'Nova versão do sistema!',
  updateAvailable: 'Uma nova atualização do sistema está disponível.',
  birthday: {
    birthdayError: 'Falha ao enviar o post.',
    dateformat: 'D [de] MMMM',
    incomingBirthday: 'Próximos Aniversários',
    sendCongratulations: 'Dê os parabéns',
  },
  bookmarks: {
    bookmarkTitle: 'Itens salvos por você',
    emptyBookmarks: 'Por enquanto, nenhum item salvo',
    emptyBookmarksSubtitle: 'Eles aparecerão aqui',
  },
  comment: {
    liked: 'Curtiram esse comentário',
  },
  comments: {
    cancel: 'Cancelar',
    commentsError: 'Não é possível enviar seu comentário pois este post foi excluido.',
    deleteAttachment: 'Excluir',
    deleteCommentDescription: 'Você tem certeza que deseja excluir este comentário?',
    deleteCommentTitle: 'Deseja excluir o comentário?',
    errorDelete: 'Não foi possível excluir este comentário.',
    leaveComment: 'Deixe um comentário',
    likeCount: '{{count}} Curtida',
    likesCount_plural: '{{count}} Curtidas',
    moreComments: 'Ver mais comentários',
    reply: 'Responder',
    seeComments: 'Ver Comentários',
    sendComment: 'Pressione Ctrl + Enter para enviar',
    sendCommentEdit: 'Pressione Ctrl + Enter para enviar ou Esc para cancelar',
    sendCommentEditMac: 'Pressione Cmd + Enter para enviar ou Esc para cancelar',
    sendCommentMac: 'Pressione Cmd + Enter para enviar',
    sendCommentsButton: 'Enviar',
  },
  common: {
    about: 'Sobre',
    accept: 'Aceitar',
    actions: 'Ações',
    active: 'Ativo',
    admin: 'Administrador',
    archive: 'Arquivar',
    archiveTitle: 'Deletar este banner?',
    delete: 'Deletar',
    attachment: 'Anexo',
    back: 'Voltar',
    backToLogin: 'Voltar para o login',
    birthDate: 'Data de nascimento',
    birthday: 'Aniversário',
    cancel: 'Cancelar',
    changeCover: 'Trocar capa',
    changePhoto: 'Atualizar foto',
    city: 'Cidade',
    collapse: 'Recolher',
    clearFields: 'Limpar campos',
    clearFilters: 'Limpar filtros',
    clearSelection: 'Limpar seleção',
    close: 'Fechar',
    collaborator: 'Colaborador',
    company: 'Empresa',
    contact: 'Contato',
    creationDate: 'Data de criação',
    details: 'Detalhes',
    description: 'Descrição',
    date: 'Data',
    deactivate: 'Desativar',
    department: 'Departamento',
    disabled: 'Desativado',
    doNotRefuse: 'Não recusar',
    download: 'Baixar',
    dragDropFile: 'Arraste e solte o arquivo aqui',
    dropImage: 'Arraste uma imagem',
    edit: 'Editar',
    edited: 'Editado',
    email: 'E-mail',
    expand: 'Expandir',
    filter: 'Filtro',
    follow: 'Seguir',
    fullName: 'Nome Completo',
    gender: 'Gênero',
    general: 'Geral',
    groups: 'Grupos',
    groupsIn: 'Grupos que participo',
    groupsPinned: 'Grupos Fixados',
    groupManager: 'Administrador de Grupo',
    help: 'Ajuda',
    language: 'Idioma',
    legal: 'Legal',
    linkedin: 'Linkedin',
    logout: 'Sair',
    member: 'Membro',
    members: 'Membros',
    mentions: 'Menções',
    message: 'Mensagem',
    name: 'Nome',
    next: 'Próximo',
    notificationSettings: 'Configurações de Notificação',
    occupation: 'Cargo',
    openChat: 'Abrir Chat',
    optional: 'Opcional',
    orderBy: 'Ordernar por',
    password: 'Senha',
    photos: 'Fotos',
    newPassword: 'Nova senha',
    passwordConfirmation: 'Repetir nova senha',
    participation: 'Participação',
    participating: 'Participando',
    participate: 'Participar',
    people: 'Pessoas',
    permissions: 'Permissões',
    phone: 'Telefone',
    phoneExtension: 'Ramal',
    photo: 'Foto',
    position: 'Cargo',
    post: 'Post',
    privacy: 'Privacidade',
    profession: 'Profissão',
    refresh: 'Recarregar',
    refuse: 'Recusar',
    removePhoto: 'Remover foto',
    resetPassword: 'Resetar Senha',
    required: 'Obrigatório',
    save: 'Salvar',
    savePassword: 'Salvar senha',
    schedulingDate: 'Data de agendamento',
    seeMore: 'Ver mais ({{count}})',
    selectAll: 'Selecionar todos',
    sendMessage: 'Enviar mensagem',
    settings: 'Configurações',
    site: 'Site',
    skype: 'Skype',
    status: 'Status',
    support: 'Suporte',
    survey: 'Enquete',
    title: 'Título',
    toAdd: 'Adicionar',
    unfollow: 'Deixar de seguir',
    unit: 'Unidade',
    users: 'Usuários',
    value: 'Valor',
    video: 'Vídeo',
    videos: 'Vídeos',
    view: 'Visualizar',
    viewAll: 'Ver todos',
    viewProfile: 'Visualizar perfil',
    whatsApp: 'WhatsApp',
    modalMessage: 'Excluir membro',
    modalOkButton: 'Sim, excluir',
    modalBodyMessage: 'Você realmente quer excluir {{isSelected}} desse grupo?',
    modalBodyMessageLength: 'Você realmente quer excluir {{isSelected}} pessoas desse grupo?',
  },
  conjunction: {
    and: 'e',
    by: 'por',
    in: 'em',
    or: 'ou',
  },
  cookies: {
    continue: 'Prosseguir',
    disclaimer:
      'Nós usamos cookies de estatísticas de visitas e outras tecnologias para melhorar sua experiência de navegação. Ao continuar, você concorda com nossa política de privacidade. Para mais informações veja nossas',
  },
  errors: {
    generic: 'Desculpe, algo deu errado. Por favor tente novamente mais tarde',
    invalidDate: 'Data inválida',
    network: 'Há algum problema com sua internet',
    unableChangeContent: 'Não foi possível alterar este conteúdo',
    unableSaveContent: 'Não foi possível salvar este conteúdo',
  },
  feed: {
    all: 'Todos',
    'custom-filters': 'Filtros customizados',
    emptyFeedTitle: 'Não há posts aqui',
    emptyFeedSubtitle: 'Seja o primeiro a criar.',
    following: 'Seguindo',
    hideBannerTooltip: 'Esconder banner',
    home: 'Página Inicial',
    manageBanner: 'Gerenciar banner',
    private: 'Privado',
    public: 'Público',
    recentPosts: 'Últimas novidades',
    search: 'Busque por palavras chaves',
    showBannerTooltip: 'Mostrar banner',
    visibility: 'Visualizar posts de',
    content: {
      all_types: 'Todos os tipos',
      files: 'Arquivos',
      images: 'Imagens',
      label: 'Conteúdo',
      videos: 'Vídeos',
    },
    date: {
      anytime: 'Qualquer data',
      at: 'às',
      custom: 'Período customizado',
      label: 'Data',
      month: 'Esse Mês',
      today: 'Hoje',
      week: 'Essa Semana',
      yesterdayAt: 'ontem às',
    },
    messages: {
      resetPassword: 'Para redefinir sua senha, é necessário sair da sessão atual',
    },
  },
  files: {
    add: 'Adicionar',
    discard: 'Descartar',
    fileLimit: 'Limite de imagens atingido',
    forbiddenAttachment: 'Esta extensão de imagem não é permitida',
    videoSizeAttachment:
      'O vídeo não pode exceder {{size}}MB. Você pode continuar publicando seu post, mas o vídeo não será publicado.',
    forbiddenFiles: 'O arquivo não pode ser: {{extensions}} ou exceder {{size}}MB',
    maxSize: 'Você só pode enviar até {{size}} imagens. ',
    recentFiles: 'Recentes',
    removeAttachment: 'Excluir anexo',
    searchFiles: 'Buscar arquivos',
    errors: {
      'file-extension-forbidden': "Extensão de arquivo '{{extension}}' não é permitida",
      'file-invalid-type': 'Tipo de arquivo inválido',
      'file-too-large': 'Arquivo muito grande',
      'file-too-small': 'Arquivo muito pequeno',
      'too-many-files': 'Muitos arquivos',
    },
  },
  filter: {
    search: 'Pesquisar',
    selectAll: 'Selecionar todos',
    selected: 'Selecionado {{count}}',
    selected_plural: 'Selecionados {{count}}',
    tooltipCount: 'Para {{targets}}',
    tooltipCount_interval:
      '(0)[Selecione pessoas];(1-3)[Para {{targets}}];(4-inf)[Para {{targets}} e mais {{restCount}}];',
    undoFilter: 'Desfazer',
    viewAll: 'Ver todos',
    viewSelected: 'Ver selecionados',
  },
  formats: {
    birthday: '{{prefix}} {{day}}, {{weekDay}}',
    date: 'DD/MM/YYYY',
    datetime: 'DD/MM/YYYY - HH:mm',
    fullDatetime: 'DD/MM/YYYY [às] HH:mm',
    time: 'HH:mm',
    timeSeconds: 'HH:mm:ss',
  },
  global: {
    back: 'Voltar',
    cancel: 'Cancelar',
    continue: 'Continuar',
    delete: 'Excluir',
    edit: 'Editar',
    ok: 'Ok',
    today: 'Hoje',
  },
  groups: {
    addAttribute: 'Adicionar filtro',
    addPeople: 'Adicione pessoas',
    addPeopleHint: 'Convide pessoas para fazer parte do grupo',
    addPhoto: 'Adicionar foto',
    administratorsHint: 'Convide pessoas de sua confiança para te ajudarem a administrar o grupo',
    allDone: 'Tudo pronto!',
    changeCover: 'Mudar capa',
    changePhoto: 'Atualizar foto',
    changeSegments: 'Alterar segmentos',
    continueEditing: 'Continuar editando',
    createGroup: 'Criar grupo',
    createNewGroup: 'Criar novo grupo',
    deleteChannel: 'Excluir o canal',
    deleteGroup: 'Excluir grupo',
    deleteMessage:
      'Você realmente quer excluir este grupo? A exclusão de um grupo é uma ação irreversível e todos os membros perderam acesso a ele. Todos os posts, arquivos e conversas serão perdidos.',
    deletePeopleFromGroups: 'Excluir do grupo',
    demoteAdmin: 'Rebaixar para membro',
    descriptionHint: 'Uma breve introdução do que será abordado no grupo',
    descriptionMaxLength: 'A descrição deve ter no máximo {{maxLength}} caracteres',
    editGroup: 'Gerenciar',
    editSegments: 'Editar segmentos',
    editorPlaceholder: 'Publique algo para seus colegas',
    emptyJoinedGroup:
      'Você não participa de nenhum grupo, crie um grupo ou descubra os que já existem na rede',
    existingGroups: 'Encontramos {{count}} Grupos',
    fixedGroupsEmpty: 'O administrador da rede não definiu grupos fixos',
    goToGroup: 'Ir para o grupo',
    groupAdministrator: 'Administrador',
    groupAdministrators: 'Administradores',
    groupCreated: 'Seu grupo foi criado',
    groupMembers: '{{count}} participante',
    groupMembers_plural: '{{count}} participantes',
    groupName: 'Nome do grupo',
    groupOwner: 'Gestor do grupo',
    groupPrivacy: 'Privacidade do grupo',
    groupUpdated: 'O grupo foi atualizado',
    invitePeople: 'Convidar pessoas',
    join: 'Participar',
    joined: 'Participando',
    leaveChannel: 'Sair do canal',
    leaveGroup: 'Sair do grupo',
    linkHint:
      '“Adicione um link externo, que ficará visível para as pessoas que participam do grupo. Por exemplo, você pode adicionar o link de um drive de arquivos"',
    manualOption: 'Manualmente - escolher os membros que farão parte do grupo',
    members: 'Membros',
    participantsCanPublish: 'Participantes podem publicar no grupo',
    pinGroup: 'Fixar Grupo',
    postAs: 'Postar como',
    postTo: 'Publicar para',
    private: ' Privado - Somente quem você convidar terá acesso ao grupo',
    privateGroup: 'Grupo privado',
    promoteToAdmin: 'Promover a administrator',
    promoteToManager: 'Promover a gestor do grupo',
    public: 'Público - Todas as pessoas da rede terão acesso ao grupo',
    publicGroup: 'Grupo público',
    removeFromGroup: 'Remover {{name}} do grupo',
    removePhoto: 'Remover foto',
    ruleToAddMembers: 'Regra para adicionar membros',
    saveChanges: 'Salvar',
    search: 'Busque por grupos',
    searchPeople: 'Pesquisar pessoas',
    segmentedOption: 'Segmentado - criar regra para adicionar membros automaticamente',
    segmentedGroupTitle: 'Grupo segmentado - <1>Voce poderá adicionar pessoas por segmentos</1>',
    segmentationHint: 'Escolha como os membros serão adicionados ao grupo',
    shareGroupLink: 'Compartilhar link do grupo',
    toAdmin: 'para administradores',
    undoChanges: 'Desfazer alterações',
    unpinGroup: 'Desafixar grupo',
    wantToSaveChanges: 'Deseja salvar as alterações?',
    warningChangeSegmentMessage:
      'Se você continuar as alterações serão concluídas em até 5 minutos',
    warningSegmentTitle: 'Você fez alterações importantes na segmentação. Deseja continuar?',
    warningSegmentToManual:
      'Ao alterar um grupo segmentado para manual, novos membros não serão mais adicionados automaticamente',
    warningSegmentToPublic:
      'Ao alterar o grupo para público, este deixará de funcionar como um grupo segmentado. Os membros atuais serão mantidos',
  },
  header: {
    appEmpty: 'Não existem aplicativos disponíveis.',
    emptyNotifications: 'Não existem notificações para você',
    search: 'Busca',
    searchNoGroups: 'Nenhum grupo encontrado',
    searchNoUsers: 'Nenhum usuário encontrado',
    searchPlaceholder: 'Buscar por pessoas e grupos',
  },
  invite: {
    emptyFields: 'Preencha todos os campos antes de prosseguir.',
    genericError: 'Não foi possível enviar o convite. Tente novamente!',
    iniviteNewMemebers: 'Convide novos colaboradores para se conectarem a sua rede',
    inviteInstructions: 'Digite: nome, email do colaborador, e quais permissões ele vai ter.',
    newMembers: 'Adicionar novos colaboradores',
    userExists: 'E-mail do usuário já existe na rede',
    userInvited: 'Usuário convidado!',
    wrongEmail: 'E-mail inválido.',
  },
  login: {
    emailPlaceholder: 'Email ou Usuário',
    forgotPassword: 'Esqueceu sua senha?',
    passwordPlaceholder: 'Digite sua senha',
    passwordConfirmPlaceholder: 'Confirme sua senha',
    rememberMe: 'Mantenha me logado',
    signIn: 'Entrar',
    title: 'Bem-vindo',
    waitSeniorX: 'Por favor aguarde um momento, estamos preparando tudo para você...',
    casErrors: {
      tokenNotFound: 'Token não foi encontrado',
    },
    errors: {
      401: 'Usuário ou senha estão incorretos, tente novamente',
      invalidValue: 'Seu e-mail, usuário ou senha estão incorretos.',
      recaptcha:
        'Você digitou a senha errada algumas vezes. Por favor aguarde alguns minutos ou crie uma nova senha',
    },
    seniorXErrors: {
      401: 'Seu usuário foi desativado ou não está disponível. Entre em contato com o responsável da rede.',
      generic:
        'Não conseguimos fazer o seu login. Tente novamente ou entre em contato com o responsável da rede',
    },
  },
  menu: {
    about: 'Sobre',
    administration: 'Administração',
    bookmarks: 'Itens salvos',
    dashboard: 'Dashboard',
    events: 'Eventos',
    feed: 'Posts',
    groups: 'Grupos',
    people: 'Pessoas',
    'saved-items': 'Itens Salvos',
    tasks: 'Tarefas',
    explore: {
      explore: 'Explore',
      people: 'Pessoas',
      'top-posts': 'Top posts',
      trending: 'Trending Tags',
    },
  },
  'new-post': {
    profileConfirm: 'Você está postando como <1>{{name}}</1><2/>isso está certo?',
    profileConfirmCancel: 'Sim, está!',
    profileConfirmOk: 'Quero mudar',
    visibility: {
      admin: 'Administradores do grupo',
      adminTooltip: 'Apenas os administradores do grupo receberão este post',
      followers: 'Seguidores',
      membersTooltip: 'Apenas as pessoas selecionadas receberão este post',
      network: 'Público',
      networkMembers: 'Participantes selecionados',
      networkTooltip: 'Todas as pessoas da rede receberão este post',
      'people-selection-placeholder': 'Busque por pessoas',
      private: 'Privado',
      public: 'Público',
      title: 'Visibilidade do post',
    },
  },
  notification: {
    title: 'Notificações',
    panelTitle: 'Navegue pelo painel para visualizar suas notificações',
    birthdays: {
      birthdayMessage: 'Hoje é o aniversário de {{name}} ',
      birthdayMessageWithPermission: 'Dê os parabéns a {{name}} pelo seu aniversário',
      birthdayTitle: 'Aniversário',
      birthdayTitle_plural: '{{count}} aniversários',
      sendBirthdays: 'Deixe uma mensagem',
    },
    comments: {
      commentMessage: '{{name}} comentou em seu post',
      commentMessageRelated: '{{name}} comentou em um post que você está relacionado',
      commentMessageRelated_plural: '{{name}} comentaram em um post que você está relacionado',
      commentMessage_plural: '{{name}} comentaram em seu post',
      commentTitle: 'Novo comentário',
      commentTitle_plural: '{{count}} Novos comentários',
    },
    chat: {
      channelCreatedTitle: 'Novo canal',
      channelCreatedBody: 'Agora voce está participando do canal {{name}}',
    },
    likes: {
      likeComment: '{{name}} reagiu ao seu comentário',
      likeCommentRelated: '{{name}} reagiu a um comentário que você está relacionado',
      likeCommentRelated_plural: '{{name}} reagiram a um comentário que você está relacionado',
      likeComment_plural: '{{name}} reagiram ao seu comentário',
      likePost: '{{name}} reagiu ao seu post',
      likePostRelated: '{{name}} reagiu a um post que você está relacionado',
      likePostRelated_plural: '{{name}} reagiram a um post que você está relacionado',
      likePost_plural: '{{name}} reagiram ao seu post',
      likeTitle: 'Nova Reação',
      likeTitle_plural: '{{count}} Novas Reações',
    },
    mentions: {
      mentionMessageComment: '{{name}} mencionou você em um comentário',
      mentionMessagePost: '{{name}} mencionou você em um post',
      mentionTitle: 'Nova menção',
    },
    post: {
      privatePostBody: '{{name}} fez um post privado para você',
      privatePostTitleGroup: 'Novo post privado em {{name}}',
      privatePostTitle: 'Novo post privado',
      publicPostBody: '{{name}} fez um post',
      publicPostTitleGroup: 'Novo post em {{name}}',
      publicPostTitle: 'Novo post público',

      scheduleReadyBody: 'Seu post agendado está disponível',
      scheduleReadyTitle: 'Post agendado publicado',
    },
    group: {
      groupAddedTitle: 'Novo grupo!',
      groupAddedBody: 'Agora você faz parte do grupo {{name}}',
    },
    prefix: {
      messageMultiple: ' e {{count}} outra pessoa ',
      messageMultiple_plural: ' e {{count}} outras pessoas ',
    },
  },
  notificationPermissionDialog: {
    allow: 'Permitir',
    block: 'Bloquear',
    description:
      'Sempre que você receber uma nova notificação, ela será mostrada na sua área de trabalho.',
    title: 'Permitir notificações',
  },
  pages: {
    users: {},
    forgotPassword: {
      description:
        'Insira seu email, e será enviado um email com instruções para redefinir sua senha',
      title: 'Esqueceu sua senha?',
      successMessage: 'Enviamos um email com instruções para redefinir sua senha',
      errors: {
        204: 'Email não encontrado',
      },
    },
    resetPassword: {
      successMessage: 'Senha redefinida com sucesso',
      title: 'Agora você pode criar uma nova senha',
      errors: {
        invalidToken: 'Token inválido',
        tokenExpired:
          'O link de recuperação de senha que você utilizou expirou. Solicite novamente',
        currentPassword: 'Usuário e/ou senha incorretos.',
        passwordNotUpdated: 'Senha não atualizada, tente mais tarde.',
      },
      requirements: {
        lowerCase: 'Deve conter pelo menos uma letra minúscula',
        upperCase: 'Deve conter pelo menos uma letra maiúscula',
        number: 'Deve conter pelo menos um número',
        specialCharacter: 'Deve conter pelo menos um caractere especial',
        length: 'Deve ter 8 caracteres ou mais',
      },
      strengthLevels: {
        tooShort: 'Muito curto',
        weak: 'Fraco',
        good: 'Bom',
        strong: 'Forte',
        veryStrong: 'Muito forte',
      },
    },
    settingsGeneral: {
      currentPassword: 'Senha atual',
      description: 'Mantenha-se sempre seguro alterando sua senha periódicamente',
      forgotMyPassword: 'Esqueci minha senha atual',
      submitText: 'Mudar senha',
      title: 'Alterar Senha',
    },
  },
  permissionNotification: {
    title: 'Permissão de Notificação',
    body: 'Para uma melhor experiência, permita-nos enviar notificações na sua área de trabalho.',
    dontShowAgain: 'Não exibir novamente',
    allowSend: 'Permitir',
    dontAllowSend: 'Não permitir',
  },
  post: {
    blockCommenting: 'Os comentários deste post foram desativados',
    buttonReturnFeed: 'Voltar para o Feed',
    comment: 'Comentar',
    comments: '{{count}} Comentário',
    comments_plural: '{{count}} Comentários',
    deletePostDescription: 'Você tem certeza que deseja excluir esse post?',
    deletePostTitle: 'Deseja excluir um post?',
    disableComment: 'Desativar todos os comentários',
    enableComment: 'Ativar todos os comentários',
    errorDelete: 'Não foi possível excluir este post',
    errorExecute: 'Não foi possível executar essa ação no momento',
    errorSend: 'Falha ao enviar o post.',
    addPersonSendPost: 'Adicione pelo menos uma pessoa para o envio da publicação. ',
    followers: 'Seguidores',
    highlight: 'Destaque',
    highlightPost: 'Destacar',
    messageError: 'Este conteúdo foi excluído ou está indisponível.',
    myFeed: 'Meu Feed',
    newPosts: 'novos posts',
    news: 'Comunicados',
    pinHint: 'Seu post ficará fixo no widget',
    pinPost: 'Fixar post',
    postAsNews: 'Postar como Comunicado',
    postSubTitleError: 'Desculpe, seu conteúdo não foi encontrado.',
    postTitleError: 'Encontramos um problema',
    private: 'Privado',
    privateCount: '{{count}} pessoa',
    privateCount_plural: '{{count}} pessoas',
    privateTitle: 'Privado para...',
    privateTo: 'Privado para',
    processingVideo:
      'Seu post foi criado com sucesso, mas só estará visível quando o vídeo estiver pronto.',
    processingVideoTitle: 'Processando vídeo',
    public: 'Público',
    removeHighlight: 'Remover destaque',
    removeSavedItem: 'Remover de "itens salvos"',
    results: 'Resultados',
    save: 'Salvar',
    savedPost: 'Seu post foi salvo',
    savedPostRemoved: 'Removido de "itens salvos"',
    saveItem: 'Salvar em "itens salvos"',
    saveItemHint: 'ficará em itens salvos',
    sendPost: 'Postar',
    sendPostRequiments: 'Escreva uma mensagem para enviar',
    share: 'Compartilhar',
    shared: 'Compartilhou um',
    sharedType: 'post',
    shares: '{{count}} Share',
    shares_plural: '{{count}} Shares',
    tellUsSomething: 'Nos conte algo,',
    unpin: 'Desafixar post',
    videoReady:
      'O processamento do vídeo terminou e seu post está publicado. Clique para visualizá-lo.',
    videoReadyTitle: 'Seu vídeo está pronto!',
    viewLess: 'Ver menos',
    viewLiked: 'Curtiram esse post',
    viewMore: 'Ver mais',
    viewedPost: 'Visualizaram esse post',
    viewReacted: 'Reações',
    draft: {
      savedDrafts: 'Rascunhos salvos',
    },
    errors: {
      feedError: 'Ocorreu um problema para exibir os posts',
      filterEmpty: 'Nenhum post foi encontrado com esses filtros',
      filterEmptySub: 'Revise os filtros e tente novamente',
      maxPinnedPosts: 'Já existem {{count}} posts fixados como widget',
    },
    schedule: {
      attachment: '{{count}} Anexo',
      attachment_plural: '{{count}} Anexos',
      deleteScheduledPostTitle: 'Excluir este post agendado?',
      inactive: 'Você receberá uma notificação quando seu post for publicado',
      modalDescription: 'Escolha em qual data e horário este post poderá ser publicado',
      noSchedule: 'Não há posts agendados',
      publishNow: 'Enviar agora',
      schedule: 'Agendar',
      schedulePost: 'Agendar post',
      scheduledFor: 'Agendado para',
      scheduledPosts: '{{count}} Posts agendados',
      success: 'Seu post está agendado.',
      successByMember: 'Seu post está agendado e disponível na tela inicial para edição.',
    },
    warning: 'Esta ação foi cancelada',
  },
  reactions: {
    all: 'Todas',
    like: 'Gostei',
    love: 'Amei',
    applause: 'Parabéns',
    support: 'Apoio',
  },
  security: {
    dontShowAgain: 'Não mostrar novamente',
    securityModal: 'Como você entra na sua rede?',
    validation: {
      invalidCode: 'Código de verificação inválido',
    },
    verificationPane: {
      code: 'Código',
      didntGetCode: 'Não recebi o código',
      title: 'Verificação do código',
      title_email: 'Verificação do e-mail',
      title_phone: 'Verficação do número',
      enterCode: 'Insira o código',
      description: 'Insira o código de 6 dígitos que enviamos',
      description_email: 'Insira o código de 6 dígitos que enviamos para o e-mail',
      description_phone: 'Insira o código de 6 dígitos que enviamos para o número',
      resendCode: 'Seu código foi reenviado com sucesso. Você pode solicitar o novo código em',
      verify: 'Verificar',
    },
  },
  settings: {
    language: 'English',
    settings: 'Ajustes',
  },
  shortcuts: {
    shortcuts: 'Atalhos',
  },
  summary: {
    followers: 'Seguidores',
    following: 'Seguindo',
    posts: 'Posts',
  },
  survey: {
    anonymousSurvey: 'Esta é uma enquete anônima',
    checkAnonymous: 'Marcar esta enquete como anônima',
    createSurvey: 'Criar enquete',
    editSurvey: 'Você pode alterar o título, data de término e se a enquete será anônima',
    optionCount: 'Opção {{number}}',
    personsVoted: 'Pessoas que votaram',
    scheduleSurvey: 'Agendar enquete',
    selectSurveyEnd: 'Selecione o término da enquete',
    subtitle: 'Crie uma enquete para que seus colegas possam interagir',
    survey: 'Enquete',
    surveyEnded: 'Terminou em {{date}}',
    surveyEnds: 'Termina em {{date}}',
    surveyHint: 'Clique nas opções abaixo para deixar seu voto',
    surveyHintEnd: 'O período de votação desta enquete se encerrou, confira o resultado abaixo',
    votes: '{{count}} Voto',
    votes_plural: '{{count}} Votos',
    errors: {
      castVote: 'Seu voto não foi enviado',
    },
  },
  tabs: {
    feed: 'Feed',
    following: 'Seguindo',
    popular: 'Popular',
  },
  users: {
    searchPeople: 'Busque pessoas por nome',
    selectCompany: 'Selecione uma empresa',
    selectDepartment: 'Selecione um departamento',
    selectPosition: 'Selecione um cargo',
    selectUnit: 'Selecione uma unidade',
    inviteMembers: 'Estamos convidando {{count}} novo colaborador para fazer parte de sua rede. ',
    inviteMembers_plural:
      'Estamos convidando {{count}} novos colaboradores para fazer parte de sua rede. ',
  },
  validation: {
    fieldRequired: 'Este campo é obrigatório',
    invalidEmail: 'E-mail inválido',
    invalidField: 'Este campo é inválido',
    invalidPhone: 'O telefone é inválido',
    linkInvalid: 'O formato do link é inválido',
    unmatchPassword: 'A senha informada não corresponde com a nova senha',
  },
  welcome: {
    clearPhoto: 'Remover foto',
    cropDescription: 'Selecione a área da imagem para a foto',
    cropTitle: 'Foto de perfil',
    welcome: 'Seja bem-vindo ao',
    step1: {
      title: 'Trabalho colaborativo',
      description:
        'Compartilhe arquivos, crie grupos, canais e mantenha uma comunicação centralizada',
    },
    step2: {
      title: 'Chat em grupo',
      description: 'Use o chat em grupo para enviar mensagens rápidas para sua equipe',
    },
    step3: {
      title: 'Não perca nada!',
      description: 'Acompanhe seu feed e fique por dentro do que acontece no trabalho',
    },
  },

  follow: {
    follow: 'Seguir',
    unfollow: 'Deixar de Seguir',
    following: 'Seguindo',
    followers: 'Seguidores',
  },

  termsOfUse: {
    menuTitle: 'Termos de uso',
    title: 'Termos de Uso 4bee Work+ ',
    confirmRefuse: 'Recusar os termos de uso e LGPD?',
    updatedDate: 'Termos atualizados em',
    body: `<p>O presente Termo de Uso, em conjunto com a Política de Privacidade (disponível neste site) são os termos de serviços que regem o relacionamento com os usuários e outras pessoas, tanto físicas quanto jurídicas, que interagem com o 4bee Work+ . Ao usar ou acessar este site, o usuário concorda com este instrumento, aceitando automaticamente todas as regras e obrigações, ficando vedado qualquer solicitação de alteração ou não aceitação posterior à utilização do mesmo:</p>
    <p><b>1.</b> O Termo de Uso estará sempre disponível neste site, a partir de sua página inicial, disponível no endereço <strong>4bee.com.br</strong>.</p>
    <p><b>2.</b> Periodicamente, a 4bee Work+ poderá alterar ou corrigir esses Termos de Uso notificando os usuários e solicitando um novo aceite, sendo que as alterações e/ou modificações tornar-se-ão válidas a partir da veiculação neste site. O uso dos serviços após a data inicial de vigência das alterações será o consentimento do usuário quanto aos termos alterados.</p>
    <p><b>3.</b> O usuário poderá ser solicitado a fornecer informações em formulários ou cookies neste site. O usuário declara e dá garantia da veracidade, exatidão e atualização de toda e qualquer informação prestada, sendo mantidas desta forma durante toda a sua permanência como usuário deste site.</p>
    <p><b>4.</b> Os usuários individuais podem formar um grupo para um segmento de público ("Grupo"). Cada grupo pode ter um ou mais administradores (o "Administrador do Grupo"). Um grupo pode ser designado como aberto a qualquer usuário em uma rede (um "grupo público") ou um grupo pode ser limitado a determinados usuários em uma rede com o assunto para a aprovação do administrador do grupo (um "Grupo Privado"). O administrador do grupo pode adicionar ou remover usuários desse grupo específico. Os usuários do grupo, bem como as informações veiculadas no mesmo, deverão obedecer às regras presentes neste termo.</p>
    <p><b>5.</b> No que se refere ao conteúdo protegido pelas leis de direitos de propriedade intelectual, como fotos, vídeos e textos, o usuário concede uma licença não exclusiva à 4bee Work+, permitindo a esta a utilização de qualquer conteúdo publicado pelo usuário cadastrado. Tal utilização será feita de acordo com a regência das normas e padrões dispostos no item 4 da POLÍTICA DE PRIVACIDADE, também disponibilizada na página inicial do site.</p>
    <p><b>6.</b> A respectiva licença supra mencionada se encerra a partir do momento da exclusão do conteúdo publicado, a menos que a informação tenha sido compartilhada ou divulgada por outros usuários que não a tenham excluído.</p>
    <p><b>7.</b> A 4bee Work+ envidará seus maiores esforços para assegurar que as informações, instrumentos e materiais oferecidos neste site sejam tão atualizados, precisos e completos quanto possível. Entretanto, em nenhuma circunstância, a 4bee Work+ poderá ser responsabilizada por fatos que não lhe sejam exclusivamente atribuíveis e eivados de dolo, quanto à integridade, atualidade e exatidão de tais informações, instrumentos e materiais.</p>
    <p><b>8.</b> O usuário deverá notificar imediatamente a 4bee Work+ de qualquer violação de segurança ou uso não autorizado de sua conta e/ou registro através do e-mail suporte@4bee.com.br. Mesmo com a notificação a 4bee Work+ não será responsável por perdas causadas pelo uso não autorizado da conta do usuário.</p>
    <p><b>9.</b> A 4bee Work+ pode permanente ou temporariamente rescindir, suspender, ou se recusar a permitir o acesso do usuário ao sistema, sem aviso prévio, se houver violação de qualquer item do presente termo, incluindo as seguintes ações: a) tentar interferir, comprometer a integridade do sistema ou de segurança ou decifrar qualquer transmissão de ou para os servidores que executam o serviço, b) de tomar qualquer ação que imponha ou possa impor a nosso critério uma grande carga desproporcional à infra-estrutura do sistema, c) upload de dados inválidos, vírus, worms ou outros agentes de software através do Serviço, d) fazer passar por outra pessoa ou deturpar sua afiliação com uma pessoa ou entidade, a realização de fraude, escondendo ou tentando esconder a sua identidade, e) interferir com o funcionamento do serviço, f) não poderá intimidar ou praticar bullying contra qualquer usuário, g) não poderá praticar ato ilegal ou discriminatório, h) não poderá publicar conteúdo ou tomar qualquer atitude que infrinja ou viole os direitos alheios ou a lei, e i) não poderá publicar documentos de identificação ou informações financeiras confidenciais de outras pessoas. Em caso de rescisão, por qualquer motivo, o usuário continuará a ser vinculado pelo presente Termo.</p>
    <p><b>10.</b> A 4bee Work+ poderá remover qualquer conteúdo ou informações publicadas em caso de violação a esta declaração ou a política de privacidade.</p>
    <p><b>11.</b> Apesar da implementação comercial de medidas técnicas e organizacionais no sentido de proteger a informação pessoal e o conteúdo do usuário, bem como a modernização do sistema, a 4bee Work+ não oferece garantia, contratual ou legal, ou condições relacionadas ao uso dos serviços. O usuário está ciente de que o uso dos serviços é de sua responsabilidade e que os serviços são fornecidos no estado em que se encontram, com eventuais falhas e conforme disponibilização. A 4bee Work+ não garante a exatidão ou a atualização das informações disponíveis nos serviços, até os limites permitidos pelo ordenamento jurídico, excluindo qualquer garantia de qualidade satisfatória.</p>
    <p><b>12.</b> O usuário reconhece que o sistema de computador e internet não estão livres de falhas, e podem ocorrer períodos eventuais de inatividade, assim, a 4bee Work+ não garante que os serviços serão ininterruptos, atualizados, seguros ou sem erros, ou que não ocorrerá perda de conteúdo.</p>
    <p><b>13.</b> O usuário é responsável por proteger seu computador contra interferências, spywares ou vírus que possam ser encontrados neste serviço. Recomenda-se a instalação de um programa antivírus no computador e o mantenha atualizado.</p>
    <p><b>14.</b> O usuário admite e concorda que qualquer texto, áudio, imagem ou software veiculados através das páginas mantidas pela 4bee Work+, neste site, são protegidos por direitos de propriedade intelectual, não se limitando a direitos autorais (morais e patrimoniais), direitos sobre marcas, patentes, nomes comerciais, nomes de domínio e segredos industriais e comerciais.</p>
    <p><b>15.</b> Os direitos de propriedade intelectual sobre o conteúdo deste termo pertencem à 4bee Work+.</p>
    <p><b>16.</b> É vedado qualquer procedimento que implique engenharia reversa, descompilação, desmontagem, tradução, adaptação e/ou modificação dos Sistemas, ou qualquer outra conduta que possibilite o acesso ao seu código fonte, assim como de seus componentes, bem como qualquer alteração não autorizada pela 4bee Work+, ou mesmo de suas funcionalidades.</p>
    <p><b>17.</b> É vedado sublicenciar, ceder, vender, dar em locação, em garantia ou empréstimo, doar, alienar de qualquer forma, copiar, arrendar, modificar, transferir, total ou parcialmente o conteúdo dos Sistemas e de seu dicionário de dados, por qualquer meio de telecomunicações existentes ou que venha a ser criado, gratuita ou onerosamente, provisória ou permanente, sua permissão de uso, assim como seus manuais ou quaisquer informações relativas ao mesmo, sem a devida autorização por escrito da 4bee Work+.</p>
    <p><b>18.</b> Na ocorrência de violação reiterada da propriedade intelectual de outros usuários ou terceiros, a conta do usuário infrator poderá ser desativada, se tal medida for apropriada.</p>
    <p><b>19.</b> Em caso de reclamação contra a 4bee Work+ em relação a ações, conteúdo ou informações publicadas por usuário, o mesmo isentará a 4bee Work+ da responsabilidade sobre todos os danos, perdas e despesas de qualquer espécie decorrentes da reclamação.</p>
    <p><b>20.</b> A 4bee Work+ não controla, nem orienta as ações dos usuários, não se responsabilizando pelo conteúdo ou informações que os usuários transmitem ou compartilham no 4bee Work+ .</p>
    <p><b>21.</b> A 4bee Work+ não se responsabiliza por qualquer conteúdo ou informação ofensiva, inadequada, obscena, ilegal ou questionável de alguma forma. Também não se responsabiliza pela conduta, on line ou off line, de qualquer usuário no 4bee Work+ .</p>
    <p><b>22.</b> O usuário admite e concorda que a 4bee Work+ poderá disponibilizar no site, ou no aplicativo mobile, a opção de download de documentos de seu interesse. Em tal hipótese, o usuário não deverá utilizar tais documentos em outras mídias a não ser na memória do dispositivo em que o usuário tenha efetuado o download. Qualquer exceção a esta regra deverá estar expressamente indicada no site.</p>
    <p><b>23.</b> Talvez o usuário possa acessar sites ou serviços de terceiros disponibilizados eventualmente no site da 4bee Work+. Esta não será responsável por tais sites, serviços ou o conteúdo que possa estar disponível neles. O usuário é o único responsável pelos respectivos acessos. A utilização de sites ou serviços de um terceiro pode estar sujeita aos termos e condições do terceiro.</p>
    <p><b>24.</b> O site poderá disponibilizar publicações, matérias ou editoriais compilados ou escritos por terceiros. O site não se responsabiliza pela exatidão, procedência e autoria de tais materiais.</p>
    <p><b>25.</b> O usuário declara e garante que não registrará nomes de domínio na Internet, perante quaisquer entidades, nacionais ou estrangeiras, contendo marcas e nomes da 4bee Work+ ou de seus produtos e serviços.</p>
    <p><b>26.</b> O usuário se compromete a respeitar as marcas da 4bee Work+, não devendo utilizá-las sem prévia autorização, por escrito, da 4bee Work+, nem praticar atos que possam denegrir tais marcas.</p>
    <p><b>27.</b> Estes Termos de Uso serão regidos, exclusivamente, pelas leis brasileiras, e suas controvérsias deverão ser solucionadas entre o usuário e a 4bee Work+ no foro da Comarca da Cidade de São Paulo, Estado de São Paulo, com expressa renúncia a qualquer outro.</p>
    <p><b>28.</b> Ao navegar ou efetuar consulta pública a este site ou qualquer de suas páginas, o usuário terá declarado aceite explicitamente todos os itens dos Termos de Uso aqui estabelecidos. Caso não concordar com estes Termos de Uso, favor interromper o acesso a este site imediatamente.</p>`,
  },

  privacyPolicies: {
    menuTitle: 'Políticas de privacidade',
    title: 'Políticas de privacidade do 4bee Work+ ',
    body: `<p><b>Este documento tem o objetivo de formalizar compromisso com a segurança e a privacidade de informações coletadas dos usuários dos serviços interativos do website
    da 4bee Work+.</b></p>
    <p><b>1.</b> Ao registrar-se neste site, o usuário fornecerá informações pessoais, que incluirão, dentre outras, nome, endereço, telefone, e-mail e mercado em que atua.
    A <b>4bee Work+</b> garantirá a privacidade do usuário, bem como das informações que ele disponibilizará, de acordo com padrões rígidos de segurança e confidencialidade.</p>
    <p><b>2.</b> A <b>4bee Work+</b> não alugará, não venderá e não compartilhará as informações pessoais do usuário com outras pessoas e empresas de maneira diversa da descrita
    nesta Política de Privacidade sem o prévio consentimento do usuário.</p>
    <p><b>3.</b> Todas as informações disponibilizadas pelos usuários poderão ser coletadas e utilizadas única e exclusivamente pela <b>4bee Work+</b>, para operar e melhorar os
    serviços. Caso um determinado usuário deseje, seu registro poderá ser excluído ou desativado.</p>
    <p><b>3.1.</b> Quando for solicitada a desativação do cadastro, este será suspenso,  porém, não haverá a exclusão das informações, possibilitando a reativação do
    cadastro futuramente.</p>
    <p><b>3.2.</b> Na hipótese de exclusão de conta, esta será excluída permanentemente após alguns dias da solicitação.</p>
    <p><b>4.</b> A <b>4bee Work+</b> poderá acessar informações sobre o usuário ou divulgá-las, incluindo o conteúdo de suas comunicações, a fim de: (a) cumprir a lei ou responder
    a ordens ou processos judiciais; (b) proteger os direitos ou propriedades da <b>4bee Work+</b>; (c) agir acreditando de boa-fé que tal acesso ou divulgação é necessário para
    proteger a segurança pessoal dos funcionários da <b>4bee Work+</b>, clientes ou o público; ou d) quando expressamente autorizado pelo próprio usuário.</p>
    <p><b>5.</b> As informações sobre o cadastro no <b>4bee Work+ </b> são protegidas por senha cedida pela mesma, com o objetivo de que apenas o usuário tenha acesso às suas
    informações pessoais, sem prejuízo do determinado no item 3 deste instrumento. A senha fornecida é secreta, não podendo ser revelada a terceiros.</p>
    <p><b>6.</b> A <b>4bee Work+</b> não solicitará, em nenhuma hipótese, seja por e-mail ou telefone, a senha pessoal do usuário. O usuário deve desconectar-se do site e fechar a
    janela do seu programa de navegação quando concluir uma sessão. Trata-se de medida preventiva, para evitar a navegação irregular e indevida por terceiro não autorizado à
    utilização de sua senha.</p>
    <p><b>7.</b> Nenhuma transmissão de dados na internet é 100% segura. Sendo assim, embora a <b>4bee Work+</b> sempre faça o possível para proteger as informações pessoais dos
    usuários, não é possível garantir a segurança de todas as informações fornecidas. O usuário é o único responsável pela manutenção da privacidade e pelo sigilo de sua
    senha e/ou informações pessoais.</p>
    <p><b>8.</b> O usuário reconhece e concorda que a obtenção e o uso, por parte da <b>4bee Work+</b>, das informações por ele fornecidas conforme disposto nesta POLÍTICA DE
    PRIVACIDADE não configura nenhuma violação do direito à privacidade e ao sigilo, do direito de autor, publicidade ou qualquer outro direito relacionado à proteção de
    informações pessoais. Não obstante, o usuário está ciente de que os direitos de privacidade independem e não se confundem com direitos de propriedade intelectual,
    direitos de imagem, direitos à honra e reputação e outros direitos da personalidade, e que, portanto, nem sempre as informações que fornecer à <b>4bee Work+</b> serão
    protegidas pelos mesmos.</p>
    <p><b>9.</b> O usuário tem direito a acessar e, quando relevante, alterar e/ou atualizar suas informações pessoais por diversos canais disponíveis na <b>4bee Work+</b>. Há,
    neste site, áreas de acesso restrito. O usuário admite e concorda que o acesso indevido a tais áreas deverá ser considerada uma invasão de privacidade. A <b>4bee Work+</b>
    recomenda que o usuário consulte o Termo de Uso deste site, do qual esta POLÍTICA DE PRIVACIDADE é parte integrante.</p>
    <p><b>10.</b> Os conteúdos (texto, arquivo, imagem, som) e os aplicativos (programa, sistema) do website da <b>4bee Work+</b> são protegidos pela Lei do Software (a “Lei
    9.609/98”) e pela Lei de Direitos Autorais (a “Lei 9.610/98”), sendo vedada modificação, reprodução, armazenamento, transmissão, cópia, distribuição ou qualquer outra
    forma de utilização, sejam para fins comerciais ou não, sem autorização da <b>4bee Work+</b>.</p>
    <p><b>11.</b> A <b>4bee Work+</b> não se responsabilizará por danos e problemas decorrentes da demora, interrupção ou bloqueio nas transmissões de dados ocorridas na Internet.
    As funções deste site não se confundem com provimento de acesso à Internet, não estando a <b>4bee Work+</b> obrigada a fornecer informações sobre o fluxo de dados de usuários
    que acesso o site.</p>
    <p><b>12.</b> Sempre que outras organizações forem contratadas para prover serviços de apoio, será exigida a adequação aos nossos padrões de privacidade.</p>
    <p><b>13.</b> Tentativas de invasão aos sites da <b>4bee Work+</b> serão tratadas, conforme prescrição legal, como dano, roubo ou qualquer outra tipificação penal prevista no
    Código Penal Brasileiro ou em outras normas correlatas.</p>
    <p><b>14.</b> A <b>4bee Work+</b> ocasionalmente poderá atualizar e/ou alterar esta Política de Privacidade, a qualquer tempo, para que reflita alterações tecnológicas,
    legislativas, ou relativas a segurança. As alterações estarão sempre disponíveis neste site, tornando-se válidas a partir da data de sua publicação no site. A
    continuidade do uso do produto após a alteração constituirá aceitação desta Política de Privacidade. Esta não revoga, nem substitui outros instrumentos contratuais que
    versem sobre confidencialidade e privacidade e que vinculem a <b>4bee Work+ </b> com quaisquer dos usuários, em razão da celebração de parcerias e outros relacionamentos.</p>`,
  },

  lgpdTerms: {
    menuTitle: 'Termos da LGPD',
  },
  cookiesPolicies: {
    menuTitle: 'Políticas de cookies',
  },
  viewer: {
    cannotViewDocument: "Não é possível visualizar '{{document}}'",
    download: 'Fazer download',
  },
};

export default pt;
