import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
// HELPERS
import { preview } from 'lib/api';
import { openGalleryModal } from 'store/actions/postsActions';
// COMPONENTS
import CommentImageAttachment from './CommentImageAttachment/CommentImageAttachment';
import FileAttachment from './FileAttachment/FileAttachment';
import ImageGallery from '../ImageGallery';
import LinkAttachment from './LinkAttachment/LinkAttachment';
import VideoAttachment from './VideoAttachment/VideoAttachment';

// const mediaApi = '/api/media/getMedia?mediaId=';
const Attachment = props => {
  const { media, attachmentMode, postId, className } = props;
  const [isMediaLoading, setIsMediaLoading] = useState(true);
  const [linkInfo, setLinkInfo] = useState({});

  const dispatch = useDispatch();

  const handleClickImage = useCallback(
    activeIndex => {
      dispatch(openGalleryModal(postId, activeIndex));
    },
    [dispatch, postId]
  );

  useEffect(() => {
    let didCancel = false;

    const loadLink = async () => {
      setIsMediaLoading(true);

      let { url } = media;
      url = url.includes('http') ? url : `https://${url}`;

      if (!didCancel) {
        try {
          const linkInfo = await preview.previewLink(url);
          setLinkInfo(linkInfo);
          setIsMediaLoading(false);
        } catch (error) {
          setLinkInfo({
            url,
            title: '',
            description: 'Conteúdo do link indisponível', // TODO: Tradução
            images: [''],
          });
        } finally {
          setIsMediaLoading(false);
        }
      }
    };

    if (!Array.isArray(media)) loadLink();
    return () => {
      didCancel = true;
    };
  }, [media]);

  // const handleDownload = async (fileName, mediaId, token) => {
  //   const resp = await axios.get(fetchMedia(mediaId, token), { responseType: 'blob' });
  //   const url = window.URL.createObjectURL(new Blob([resp.data]));
  //   const link = document.createElement('a');
  //   link.href = url;
  //   link.setAttribute('target', '_blank');
  //   link.setAttribute('download', fileName);
  //   link.click();
  //   link.remove();
  //   window.URL.revokeObjectURL(url);
  // };

  const renderMediaAttachments = () => {
    const [m] = media;

    const mediaTypes = {
      1:
        attachmentMode === 'post' ? (
          <ImageGallery postId={postId} onClickImage={handleClickImage} />
        ) : (
          <CommentImageAttachment imageId={m.mediaId} alt={`comment image-${m.mediaId}`} />
        ),
      2: <VideoAttachment mediaId={m.mediaId} />,
      4: <FileAttachment fileName={m.originalFileName} fileSize={m.fileSize} mediaId={m.mediaId} />,
    };

    const mediaComponent = mediaTypes[m.mediaType];
    return mediaComponent;
  };
  const renderLinkAttachment = () => {
    if (isMediaLoading)
      return (
        <div style={{ textAlign: 'center' }}>
          <FontAwesomeIcon icon={faSpinnerThird} spin />
        </div>
      );
    return (
      <LinkAttachment
        url={linkInfo.url}
        title={linkInfo.title}
        description={linkInfo.description}
        image={linkInfo.images && linkInfo.images.length > 0 && linkInfo.images[0]}
      />
    );
  };

  return (
    <div className={className}>
      {Array.isArray(media) ? renderMediaAttachments() : renderLinkAttachment()}
    </div>
  );
};

Attachment.defaultProps = {
  attachmentMode: 'post',
};

export default React.memo(Attachment);
