import React, { useEffect, useMemo } from 'react';
import { convertFromRaw } from 'draft-js';
import flow from 'lodash/fp/flow';
import linkifyHtml from 'linkifyjs/html';

import styled from '@emotion/styled';

import { isJSON, lineBrakeToBr, mentionReplace, serializeToHtml } from 'lib/helper';

import Attachment from 'components/layout/Common/Attachment/Attachment';
import Tag from 'components/layout/Common/Tag/Tag';

import ShowMore from '../ShowMore/ShowMore';
import { linkifyHashtags } from './Post';

// #region STYLES
const Content = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.gray10};
  word-break: break-word;
  white-space: pre-wrap;

  ${({ theme }) => theme.typography.fontSmall()};

  .post-attachment {
    margin-top: 16px;
  }

  a.hashtag-link {
    color: #26a9e0;
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      color: #1e87b5;
    }
  }
`;
const TagsContainer = styled.div`
  display: flex;
  margin-top: 16px;
`;
// #endregion

function PostAttachment({ postInfo }) {
  const media = useMemo(
    () => postInfo.media || postInfo.linkInfo,

    [postInfo.linkInfo, postInfo.media]
  );

  if (!media) return null;
  return <Attachment className="post-attachment" postId={postInfo.post.postId} media={media} />;
}
function PostTag({ tags }) {
  if (!tags) return null;

  return (
    <TagsContainer>
      {tags.map(({ id, description }) => (
        <Tag key={id}>{description.replace('#', '')}</Tag>
      ))}
    </TagsContainer>
  );
}

function linkifiedContent(postInfo) {
  const { description, descriptionText } = postInfo.post;
  const media = postInfo.media || postInfo.linkInfo;
  let postText = '';
  if (isJSON(description)) {
    postText = serializeToHtml(convertFromRaw(JSON.parse(description)));
  } else {
    postText = flow(mentionReplace, lineBrakeToBr)(postInfo.mentions, description);
  }

  const content = () => {
    if (media && !Array.isArray(media)) {
      const httpRegex = /https?:\/\//;
      const previewUrl = media.url.replace(httpRegex, '');
      const textUrl = descriptionText.trim().replace(httpRegex, '');
      if (previewUrl === textUrl) return '';
      return postText;
    }
    return postText;
  };

  let linkyfiedText = linkifyHtml(content(), {
    className: 'url-link',
    target: { email: '_blank' },
  });

  linkyfiedText = linkifyHashtags(linkyfiedText);

  return linkyfiedText;
}

function PostContent({ charactersCount, showAttachments, onShowMore, postInfo }) {
  useEffect(() => {
    const handleHashtagClick = event => {
      const target = event.target;
      if (target.classList.contains('hashtag-link')) {
        event.preventDefault();
        const drawerCloseEvent = new CustomEvent('closeDrawer');
        window.dispatchEvent(drawerCloseEvent);

        const hashtag = target.getAttribute('href');
        window.location.hash = hashtag;
      }
    };

    document.addEventListener('click', handleHashtagClick);

    return () => {
      document.removeEventListener('click', handleHashtagClick);
    };
  }, []);

  return (
    <Content>
      <ShowMore
        textContent={linkifiedContent(postInfo)}
        charactersCount={charactersCount}
        onShowMore={onShowMore}
      />
      {showAttachments && <PostAttachment postInfo={postInfo} />}
      <PostTag tags={postInfo.tags} />
    </Content>
  );
}
PostContent.defaultProps = {
  showAttachments: true,
  onShowMore: undefined,
};

export default PostContent;
