import React, { forwardRef, Fragment, useCallback, useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import flow from 'lodash/fp/flow';
import linkifyHtml from 'linkifyjs/html';
import { convertFromRaw } from 'draft-js';
import { useInView } from 'react-intersection-observer';
import PropTypes from 'prop-types';

// GLOBAL COMPONENTS
import { Animated } from 'react-animated-css';

import { useAuth } from 'hooks';
import { increasePostView } from 'lib/apiActions';
import { mentionReplace, lineBrakeToBr, serializeToHtml, isJSON } from 'lib/helper';
import { DEVICE_PLATFORMS, POST_VIEW_TYPE } from 'lib/constants';
import { closeGalleryModal, focusCommentField } from 'store/actions/postsActions';
import { makeGetPostSelector, makeGetPostUISelector } from 'store/selectors/postSelectors';
import { canUseFunction } from 'store/selectors/authSelectors';
import ShowMore from 'components/layout/Main/Posts/ShowMore/ShowMore';

// COMPONENTS
import Attachment from 'components/layout/Common/Attachment/Attachment';
import Tag from 'components/layout/Common/Tag/Tag';
import ImageGalleryModal from 'components/layout/Common/ImageGalleryModal';
import Survey from 'components/Feed/Survey/Survey';
import EditPostModal from 'components/common/PostComposer/EditPostWrapper';

import PostHeader from '../PostHeader/PostHeader';
import PostInteractions from '../PostInteractions';
import Comments from '../../Comments/Comments';
import {
  PostContainer,
  PostContent,
  PostContentWrapper,
  PostInnerContainer,
  PostTags,
  postAttachment,
  postCss,
} from './PostStyles';

export function linkifyHashtags(htmlString, onHashtagClick) {
  return htmlString.replace(/(^|[\s>])(#[\wÀ-ú]+)/g, (fullMatch, space, hash) => {
    const encoded = encodeURIComponent(hash.slice(1));
    return `${space}<a href="#/feed?hashtag=${encoded}" id="hashtags_link" class="hashtag-link" data-hashtag="${encoded}">${hash}</a>`;
  });
}
const Post = forwardRef((props, ref) => {
  const { postId, singlePost = false, groupId, onPostDelete } = props;

  const { userId } = useAuth();

  const postRef = useRef(null);
  const postViewedRef = useRef(false);

  // #region SELECTORS
  const { current: getPost } = useRef(makeGetPostSelector());
  const { current: getPostUI } = useRef(makeGetPostUISelector());
  // #endregion

  // REDUX
  const dispatch = useDispatch();
  const { isVisible, isGalleryModalOpen } = useSelector(state => getPostUI(state, postId));
  const post = useSelector(state => getPost(state, postId));
  const { _new, publishing } = post;
  // eslint-disable-next-line no-unused-vars
  const { description, descriptionText } = post.post;

  const [style, setStyle] = useState({});
  const [commentOpen, setCommentOpen] = useState(singlePost);
  const [isLeaving, setIsLeaving] = useState(false);
  const [isEntering, setIsEntering] = useState(true);

  const commentsFunction = useSelector(state => canUseFunction(state, 'comments'), shallowEqual);
  const likesFunction = useSelector(state => canUseFunction(state, 'likes', shallowEqual));

  const [inViewRef, inView] = useInView({ initialInView: true, threshold: 0.6 });

  useEffect(() => {
    let didCancel = false;

    if (!didCancel) {
      if (_new) {
        setStyle({
          height: '0px',
          marginBottom: '0px',
        });
        requestAnimationFrame(() => {
          setStyle({ height: postRef.current.scrollHeight });
          setTimeout(() => {
            if (!didCancel) {
              setIsEntering(false);
              setStyle({});
            }
          }, 600);
        });
      }
    }

    return () => {
      didCancel = true;
    };
  }, [_new]);

  useEffect(() => {
    let didCancel = false;
    if (!isVisible) {
      requestAnimationFrame(() => {
        if (!didCancel) setIsLeaving(true);
      });
    }

    return () => {
      didCancel = true;
    };
  }, [isVisible]);

  useEffect(() => {
    let timeout;
    if (inView && !postViewedRef.current) {
      timeout = setTimeout(() => {
        (async () => {
          await increasePostView({
            postId,
            userId,
            deviceType: DEVICE_PLATFORMS.webBrowser,
            viewType: POST_VIEW_TYPE.feed,
          });
          postViewedRef.current = true;
        })();
      }, 3000);
    } else {
      clearTimeout(timeout);
    }

    return () => clearTimeout(timeout);
  }, [inView, postId, userId]);

  const getLinkyfiedContent = () => {
    const media = post.media || post.linkInfo;
    let postText = '';

    if (isJSON(description)) {
      postText = serializeToHtml(convertFromRaw(JSON.parse(description)));
    } else {
      postText = flow(mentionReplace, lineBrakeToBr)(post.mentions, description);
    }

    const content = () => {
      if (media && !Array.isArray(media)) {
        const httpRegex = /https?:\/\//;
        const previewUrl = media.url?.replace(httpRegex, '');
        const textUrl = descriptionText?.trim().replace(httpRegex, '');

        if (previewUrl === textUrl) return '';
        return postText;
      }
      return postText;
    };

    let linkyfiedText = linkifyHtml(content(), {
      className: 'url-link',
      target: { email: '_blank' },
    });

    linkyfiedText = linkifyHashtags(linkyfiedText);

    return linkyfiedText;
  };

  const setRefs = useCallback(
    node => {
      if (ref) ref.current = node;
      inViewRef(node);
    },
    [inViewRef, ref]
  );

  const handleToggleComments = useCallback(() => {
    setCommentOpen(commentOpen => !commentOpen);
  }, []);

  const handleOpenComments = useCallback(() => {
    setCommentOpen(true);
    dispatch(focusCommentField(postId));
  }, [dispatch, postId]);

  const renderTags = () => {
    if (!post.tags) return null;

    return (
      <PostTags>
        {post.tags.map(({ id, description }) => (
          <Tag key={id}>{description.replace('#', '')}</Tag>
        ))}
      </PostTags>
    );
  };

  const renderAttachments = () => {
    const media = post.media || post.linkInfo;
    if (!media) return null;

    return <Attachment css={postAttachment} postId={postId} media={media} />;
  };

  const renderContent = () => {
    return (
      <>
        <PostContent id={`content-${postId}`}>
          <ShowMore textContent={getLinkyfiedContent()} charactersCount={700} />
          <Survey postInfo={post} />
          {renderAttachments()}
          {renderTags()}
        </PostContent>
        <span></span>
      </>
    );
  };

  const renderPost = () => (
    <PostInnerContainer publishing={publishing} ref={setRefs}>
      <PostContentWrapper>
        <PostHeader
          postId={postId}
          isGroup={groupId !== undefined}
          wasUpdated={post.post.updatedDate !== null}
          isToAdmin={post.post.type === 4 && post.post.private}
          onPostDelete={onPostDelete}
        />
        {renderContent()}
        {(commentsFunction || likesFunction) && (
          <PostInteractions
            postId={postId}
            handleComments={handleToggleComments}
            handleOpen={handleOpenComments}
          />
        )}
      </PostContentWrapper>
      {commentsFunction && commentOpen && !post.post.blockCommenting && (
        <Comments
          postId={postId}
          groupId={groupId}
          commentSinglePost={singlePost}
          isLoading={false}
        />
      )}
    </PostInnerContainer>
  );

  return (
    <Fragment>
      {(_new && isEntering) || isLeaving ? (
        <Animated
          css={postCss(isEntering, isLeaving)}
          style={style}
          animationIn={_new ? 'zoomIn' : 'fadeIn'}
          animationOut="zoomOut"
          animationInDelay={_new ? 200 : 0}
          animationInDuration={_new ? 400 : 0}
          animationOutDuration={800}
          isVisible={isVisible}
          innerRef={el => {
            if (el) postRef.current = el;
          }}
        >
          {renderPost()}
        </Animated>
      ) : (
        <PostContainer>{renderPost()}</PostContainer>
      )}
      <EditPostModal postInfo={post} />
      <ImageGalleryModal
        postInfo={post}
        postId={postId}
        isModalOpen={isGalleryModalOpen}
        onClose={() => {
          dispatch(closeGalleryModal(postId));
        }}
        canComment={commentsFunction && !post.post.blockCommenting}
      />
    </Fragment>
  );
});

Post.propTypes = {
  postId: PropTypes.number.isRequired,
  singlePost: PropTypes.bool,
  groupId: PropTypes.number,
  onPostDelete: PropTypes.func,
};

export default Post;
