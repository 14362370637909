import { css } from '@emotion/react';
import { css as cssName } from '@emotion/css';
import styled from '@emotion/styled';
import { up } from 'styled-breakpoints';

export const container = css`
  padding: 0 1em;
  margin-top: 64px;
`;

export const mainContent = (loading: boolean) => {
  if (loading)
    return css`
      align-self: center;
    `;
};

export const MenuContainer = styled.aside`
  position: fixed;
  top: 64px;
  left: 0;
  z-index: 2;
  display: none;
  width: 60px;
  height: calc(100vh - 64px);
  background: ${({ theme }) => theme.colors.white};
  border-right: 1px solid ${({ theme }) => theme.colors.gray3};

  & [class^='typography_sectionTitle'] {
    color: ${({ theme }) => theme.colors.white};
  }

  ${up('tablet')} {
    display: flex;
  }
`;
export const ul = css`
  padding: 0;
  margin: 0;

  li {
    left: 0;
    height: 32px;
    padding: 0 14px;
    margin-top: 20px;
    margin-bottom: 0;
    line-height: 32px;
  }
`;

export const beeToastNotify = styled.aside`
  .Toastify__toast {
    border-radius: 8px;
  }
  .Toastify__toast-body {
  }
  .ant-alert-with-description .ant-alert-message {
    color: #002c8c;
    font-weight: bolder;
  }
  .ant-alert-with-description {
    padding: 15px;
  }
  .ant-alert-description {
    color: #003eb3;
  }
  .ant-alert-info {
    vertical-align: baseline;
  }
  .ant-alert-icon {
    position: relative;
    top: 14px;
    left: 4px;
    font-size: 18px;
  }
  .ant-btn-text {
    position: relative;
    top: 14px;
    color: #002c8c;
  }
  #update-app {
    padding: 0;
  }
  #update-app .Toastify__toast-body {
    padding: 0;
  }
`;

export const toastCss = cssName({
  // position: 'relative',
  // left: '47px',
  backgroundColor: '#E6F4FF !important',

  border: '1px solid #002c8c !important',
});

export const toastBody = cssName({ display: 'flex', justifyContent: 'space-evenly' });
