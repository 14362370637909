// GLOBAL
import React, { useCallback, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';

// GLOBAL HOOKS
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

// GLOBAL COMPONENTS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookmark,
  faEye,
  faGlobe,
  faHighlighter,
  faLock,
  faMoneyCheckPen,
  faThumbtack,
  faTrash,
  faUserFriends,
  faUserShield,
  faMessageSlash,
} from '@fortawesome/pro-solid-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { Menu, Dropdown, Button, Tooltip, Modal } from 'antd';

// UTILS
import { useAuth } from 'hooks';
import { POST_TYPES, PROFILE_TYPES } from 'lib/constants';
import { savePostToSeeLater, deleteSavedPost } from 'lib/apiActions';
import { makeGetPostSelector } from 'store/selectors/postSelectors';
import {
  deletePost,
  edit,
  pinPost,
  unpinPost,
  bookmarkPost,
  removeSavedPost,
  toggleCommenting,
} from 'store/actions/postsActions';
import { hasPermission, canUseFunction } from 'store/selectors/authSelectors';
import ProfileImage from 'components/layout/Common/ProfileImage/ProfileImage';
import UsersListModal from 'components/layout/Common/Modal/PersonList/PersonListContainer';
import UsersList from 'components/layout/Common/UsersList/UsersList';
import UserHoverCard from 'components/UserHoverCard';

import * as S from './PostHeaderStyles';

const PostHeader = ({
  postId,
  hideDropdown = false,
  className = '',
  isGroup = false,
  inWidget = false,
  wasUpdated = false,
  isToAdmin = false,
  onEdit,
  onViewClick,
  onPostDelete,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  const [privateToModalVisible, setPrivateToModalVisible] = useState(false);
  const [tgUsers, setUsers] = useState([]);

  const { userInfo, networkFunctions, networkSettings } = useAuth();

  const getTargetUsers = async () => {
    const tUsers = await axios.get(`/api/post/getTargetUsers?postId=${postId}`);

    const tgUsers = tUsers.data.map(user => ({
      userId: user.postTarget.profileId,
      name: user.postTarget.name,
      profileType: user.postTarget.profileType,
      occupation: user.occupation?.description,
      department: user.department?.description,
    }));
    setUsers(tgUsers);
    setPrivateToModalVisible(true);
  };

  // REDUX
  const getPost = useRef(makeGetPostSelector()).current;
  const dispatch = useDispatch();
  const postInfo = useSelector(state => getPost(state, postId));
  const managementPermission = useSelector(
    state => hasPermission(state, 'ContentManagement'),
    shallowEqual
  );
  const createFixedPost = useSelector(
    state => hasPermission(state, 'CreateFixedPost'),
    shallowEqual
  );
  const managementFunction = useSelector(
    state => canUseFunction(state, 'contentManagement'),
    shallowEqual
  );

  const isContentAdministrator = managementFunction && managementPermission;
  const isUserAuthor =
    postInfo.author.type === 2
      ? postInfo.post.editors.split(',').includes(userInfo.user.userId.toString())
      : postInfo.author.profileId === userInfo.user.userId;
  const postType = postInfo.post.type;
  const isSaved = postInfo.post.saved;
  const targets = postInfo.countTargetUsers;
  const { markers } = networkFunctions;
  const titleCommenting = postInfo.post.blockCommenting
    ? `${t('post.enableComment')}`
    : `${t('post.disableComment')}`;

  const onClickEdit = useCallback(() => {
    if (onEdit) onEdit();
    dispatch(edit(postId));
  }, [dispatch, onEdit, postId]);
  const onClickDelete = useCallback(() => {
    Modal.confirm({
      title: t('post.deletePostTitle'),
      content: t('post.deletePostDescription'),
      okText: t('global.delete'),
      okType: 'danger',
      centered: true,
      autoFocusButton: 'cancel',
      onOk: () => {
        dispatch(deletePost(postId));
        onPostDelete?.(postId);
      },
    });
  }, [dispatch, onPostDelete, postId, t]);

  const handleCommenting = useCallback(() => {
    dispatch(toggleCommenting(postId));
  }, [dispatch, postId]);

  const toggleHighlightHandler = useCallback(() => {
    const { postId } = postInfo.post;
    if (postInfo.post.pinned) dispatch(unpinPost({ postId, widget: false }));
    else dispatch(pinPost({ postId }));
  }, [dispatch, postInfo.post]);

  const togglePinHandler = useCallback(() => {
    const { postId } = postInfo.post;
    if (postInfo.post.bulletin) dispatch(unpinPost({ postId, widget: true }));
    else dispatch(pinPost({ postId, widget: true }));
  }, [dispatch, postInfo.post]);

  const handleSaveBookmark = useCallback(async () => {
    const saved = await savePostToSeeLater(postId);

    if (saved) {
      toast(<BookmarksToast message={t('post.savedPost')} />, {
        className: 'bee-toast gray',
        closeOnClick: false,
        hideProgressBar: true,
      });

      dispatch(bookmarkPost(postId, true));
    }
  }, [dispatch, postId, t]);
  const handleRemoveBookmark = useCallback(async () => {
    const removed = await deleteSavedPost(postId);

    if (removed) {
      toast(<BookmarksToast message={t('post.savedPostRemoved')} showButton={false} />, {
        className: 'bee-toast gray',
        closeOnClick: false,
        hideProgressBar: true,
      });

      dispatch(bookmarkPost(postId, false));

      if (location.pathname === '/bookmarks') {
        dispatch(removeSavedPost(postId));
      }
    }
  }, [dispatch, location.pathname, postId, t]);

  // RENDERS
  const saveBookmarkMenuItem = markers && !isSaved && (
    <Menu.Item onClick={handleSaveBookmark} css={S.menuItemStyle}>
      <FontAwesomeIcon icon={faBookmark} css={S.iconExactSize} />
      <S.MenuItem>{t('post.saveItem')}</S.MenuItem>
    </Menu.Item>
  );

  const saveBookmarkShortMenuItem = markers && !isSaved && (
    <Menu.Item onClick={handleSaveBookmark} css={S.menuItemStyle}>
      <S.SaveMenuItem>
        <div>
          <FontAwesomeIcon icon={faBookmark} css={S.iconExactSize} />
        </div>
        <div className="description">
          <div>{t('post.save')}</div>
          <div className="hint">({t('post.saveItemHint')})</div>
        </div>
      </S.SaveMenuItem>
    </Menu.Item>
  );

  const removeBookmarkMenuItem = markers && isSaved && (
    <Menu.Item onClick={handleRemoveBookmark} css={S.menuItemStyle}>
      <FontAwesomeIcon icon={faBookmark} css={S.iconExactSize} />
      <S.MenuItem>{t('post.removeSavedItem')}</S.MenuItem>
    </Menu.Item>
  );

  const viewPostMenuItem = inWidget && (
    <Menu.Item onClick={onViewClick} css={S.menuItemStyle}>
      <FontAwesomeIcon icon={faEye} css={S.iconExactSize} />
      <S.MenuItem>{t('common.view')}</S.MenuItem>
    </Menu.Item>
  );

  const highlightPostMenuItem = createFixedPost && postType === POST_TYPES.public && (
    <Menu.Item onClick={toggleHighlightHandler} css={S.menuItemStyle}>
      <FontAwesomeIcon icon={faHighlighter} css={S.iconExactSize} />
      <S.MenuItem>
        {postInfo.post.pinned ? t('post.removeHighlight') : t('post.highlightPost')}
      </S.MenuItem>
    </Menu.Item>
  );

  const pinPostMenuItem = createFixedPost && postType === POST_TYPES.public && (
    <Menu.Item onClick={togglePinHandler} css={S.menuItemStyle}>
      <FontAwesomeIcon icon={faThumbtack} css={S.iconExactSize} />
      <S.MenuItem>{postInfo.post.bulletin ? t('post.unpin') : t('post.pinPost')}</S.MenuItem>
    </Menu.Item>
  );

  const menuAuthor = (
    <Menu>
      {saveBookmarkMenuItem}
      <Menu.Item onClick={onClickEdit} key="edit" css={S.menuItemStyle}>
        <FontAwesomeIcon icon={faMoneyCheckPen} css={S.iconExactSize} />
        <S.MenuItem>{t('global.edit')}</S.MenuItem>
      </Menu.Item>
      {pinPostMenuItem}
      {highlightPostMenuItem}
      {networkSettings.blockCommenting && (
        <Menu.Item onClick={handleCommenting} key="comment" css={S.menuItemStyle}>
          <FontAwesomeIcon icon={faMessageSlash} css={S.iconExactSize} />
          <S.MenuItem>{titleCommenting}</S.MenuItem>
        </Menu.Item>
      )}
      {removeBookmarkMenuItem}
      {viewPostMenuItem}
      <Menu.Item onClick={onClickDelete} key="delete" css={S.menuItemStyle}>
        <FontAwesomeIcon icon={faTrash} css={S.iconExactSize} />
        <S.MenuItem>{t('global.delete')}</S.MenuItem>
      </Menu.Item>
    </Menu>
  );

  const menuAdministrator = (
    <Menu>
      {saveBookmarkMenuItem}
      {pinPostMenuItem}
      {highlightPostMenuItem}
      {removeBookmarkMenuItem}
      {viewPostMenuItem}
      <Menu.Item onClick={onClickDelete} key="delete" css={S.menuItemStyle}>
        <FontAwesomeIcon icon={faTrash} css={S.iconExactSize} />
        <S.MenuItem>{t('global.delete')}</S.MenuItem>
      </Menu.Item>
    </Menu>
  );

  const menuWidget = (
    <Menu>
      {saveBookmarkShortMenuItem}
      {removeBookmarkMenuItem}
      <Menu.Item onClick={onViewClick} css={S.menuItemStyle}>
        <FontAwesomeIcon icon={faEye} css={S.iconExactSize} />
        <S.MenuItem>{t('common.view')} </S.MenuItem>
      </Menu.Item>
    </Menu>
  );

  const menuCommonUser = (
    <Menu>
      {saveBookmarkShortMenuItem}
      {removeBookmarkMenuItem}
      {highlightPostMenuItem}
      {pinPostMenuItem}
    </Menu>
  );

  const renderSharedInfo = () => {
    const shared = postInfo.post.Type === 4;
    if (!shared) return null;

    return (
      <>
        <span>{t('post.shared')}</span>
        <S.ShareType>{t('post.sharedType')}</S.ShareType>
      </>
    );
  };
  const renderVisibility = () => {
    if (isToAdmin) {
      return (
        <>
          <S.Divider>-</S.Divider>
          <FontAwesomeIcon css={S.icon} icon={faUserShield} />
          <div>{t('groups.toAdmin')}</div>
        </>
      );
    }

    if (postType === POST_TYPES.private) {
      if (targets > 0) {
        const divId = `post_${postInfo.post.postId}`;

        return (
          <>
            <S.Divider>-</S.Divider>
            <Button
              css={S.linkButton}
              id={divId}
              icon={<FontAwesomeIcon css={S.icon} icon={faLock} />}
              type="link"
              onClick={getTargetUsers}
            >
              <Tooltip title={t('post.privateCount', { count: targets })}>
                <span>{t('post.private')} </span>
              </Tooltip>
            </Button>
            <UsersListModal
              visible={privateToModalVisible}
              title={t('post.privateTo')}
              onCancel={() => setPrivateToModalVisible(false)}
            >
              <UsersList users={tgUsers} />
            </UsersListModal>
          </>
        );
      }
    }

    if (postType === POST_TYPES.following) {
      return (
        <>
          <S.Divider>-</S.Divider>
          <FontAwesomeIcon css={S.icon} icon={faUserFriends} />
          <div>{t('post.followers')}</div>
        </>
      );
    }

    if (postType === POST_TYPES.group) {
      return null;
    }

    return (
      <>
        <S.Divider>-</S.Divider>
        <FontAwesomeIcon css={S.icon} icon={faGlobe} />
        <div>{t('post.public')}</div>
      </>
    );
  };

  const renderDropdownMenu = () => {
    if (isUserAuthor) return menuAuthor;
    if (isContentAdministrator) return menuAdministrator;
    if (inWidget) return menuWidget;
    return menuCommonUser;
  };

  const renderPostDropdown = () => {
    return (
      <Dropdown overlay={renderDropdownMenu()} trigger={['click']} placement="bottomRight">
        <Button css={S.meatballMenu} type="link">
          <FontAwesomeIcon icon={faEllipsisH} />
        </Button>
      </Dropdown>
    );
  };

  const profile = useMemo(
    () => ({
      profileId: postInfo.post.fromId,
      name: postInfo.author.name,
      profileType: postInfo.post.fromType,
      mediaId: postInfo.author.mediaId,
    }),
    [postInfo.author.mediaId, postInfo.author.name, postInfo.post.fromId, postInfo.post.fromType]
  );

  if (!postInfo) return null;
  return (
    <S.PostHeaderStyled className={className}>
      {postInfo.post.fromType !== PROFILE_TYPES.group ? (
        <UserHoverCard
          placement="topLeft"
          userId={postInfo.post.fromId}
          profileType={postInfo.post.fromType}
        >
          <Link to={`/users/${postInfo.post.fromId}`}>
            <ProfileImage profile={profile} size="sm" />
          </Link>
        </UserHoverCard>
      ) : (
        <Link to={`/groups/${postInfo.post.fromId}`}>
          <ProfileImage profile={profile} size="sm" />
        </Link>
      )}
      <S.InfoWrapper>
        {postInfo.post.fromType !== PROFILE_TYPES.group ? (
          <Link
            to={`/${postInfo.post.fromType === PROFILE_TYPES.group ? 'groups' : 'users'}/${
              postInfo.post.fromId
            }`}
          >
            <UserHoverCard placement="topLeft" userId={postInfo.post.fromId}>
              <S.HeaderTitle>{postInfo.author.name}</S.HeaderTitle>
            </UserHoverCard>
          </Link>
        ) : (
          <Link
            to={`/${postInfo.post.fromType === PROFILE_TYPES.group ? 'groups' : 'users'}/${
              postInfo.post.fromId
            }`}
          >
            <S.HeaderTitle>{postInfo.author.name}</S.HeaderTitle>
          </Link>
        )}

        {postInfo.page !== null && !isGroup && (
          <>
            <S.Conjunction>{t('conjunction.in')}</S.Conjunction>
            <Link to={`/groups/${postInfo.page.pageId}`}>
              <S.HeaderTitle as="span">{postInfo.page.name}</S.HeaderTitle>
            </Link>
          </>
        )}
        <S.PostHeaderInfo>
          <div>
            {renderSharedInfo()}
            <Link to={`/post/${postInfo.post.postId}`}>
              <PostDate date={postInfo.post.scheduledDate ?? postInfo.post.createdDate} />
            </Link>
          </div>
          {renderVisibility()}
          {wasUpdated && (
            <S.Edited>
              <Tooltip title={<PostDate date={postInfo.post.updatedDate} edited />}>
                ({t('common.edited')})
              </Tooltip>
            </S.Edited>
          )}
        </S.PostHeaderInfo>
      </S.InfoWrapper>
      {!hideDropdown && renderPostDropdown()}
    </S.PostHeaderStyled>
  );
};
PostHeader.propTypes = {
  postId: PropTypes.number.isRequired,
  hideDropdown: PropTypes.bool,
  className: PropTypes.string,
  isGroup: PropTypes.bool,
  inWidget: PropTypes.bool,
  wasUpdated: PropTypes.bool,
  isToAdmin: PropTypes.bool,
  onEdit: PropTypes.func,
  onViewClick: PropTypes.func,
  onPostDelete: PropTypes.func,
};

export default React.memo(PostHeader);

function BookmarksToast({ closeToast, showButton = true, message }) {
  const { t } = useTranslation();

  return (
    <S.ToastBody>
      <div>
        <FontAwesomeIcon icon={faCheck} />
      </div>
      <div className="message">{message}</div>
      {showButton && (
        <div>
          <S.BookmarksLink onClick={closeToast} to="/bookmarks">
            {t('check')}
          </S.BookmarksLink>
        </div>
      )}
    </S.ToastBody>
  );
}

export function PostDate({ date, edited = false }) {
  const { t } = useTranslation();

  const formattedDate = () => {
    const momentDate = moment(date);
    const now = moment();
    const dateDiff = now.diff(momentDate, 'days');

    const cal = momentDate.calendar(now, {
      sameDay: `[${t('feed.date.at')}] LT`,
      lastDay: `[${t('feed.date.yesterdayAt')}] LT`,
      lastWeek: 'lll',
      sameElse: () => {
        if (dateDiff > 15) return 'll';
        return 'lll';
      },
    });

    return cal;
  };

  return (
    <>
      {edited && t('common.edited')} {formattedDate()}
    </>
  );
}
