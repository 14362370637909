// GLOBAL
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Select, Button } from 'antd';

// HOOKS
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAuth } from 'hooks';
import { MEDIA_TYPES, POST_TYPES } from 'lib/constants';
// REDUX
import { tabChanged, updateFeedFilters } from 'store/actions/postsActions';
import { changeFeedConfig } from 'store/actions/uiActions';

import Sider from 'components/layout/LeftSide/Sider/Sider';
import SearchInput from 'components/common/SearchInput';

import * as S from './SidebarStyles';

const Sidebar = () => {
  const { t } = useTranslation();
  const { networkFunctions } = useAuth();

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');
  const [contentType, setContentType] = useState<MediaTypes | 0>(0);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const hashtag = params.get('hashtag');

    if (hashtag) {
      const completeHashtag = `#${hashtag}`;

      setSearchQuery(completeHashtag);

      dispatch(changeFeedConfig({ resultsTabVisible: true }));
      dispatch(tabChanged('search'));
      dispatch(
        updateFeedFilters({
          query: completeHashtag,
          postTypes: POST_TYPES.all,
          mediaType: 0,
        })
      );
      //params.delete('hashtag');
      //history.replace({ search: params.toString() });
    }
  }, [location.search, dispatch, navigate]);

  const queryPostsHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const contentChangeHandler = (value: MediaTypes | 0) => {
    setContentType(value);
  };

  const searchClickHandler = () => {
    searchPostsHandler();
  };

  const searchPostsHandler = (value?: string) => {
    const clearValue = value === '';

    dispatch(changeFeedConfig({ resultsTabVisible: !clearValue }));
    dispatch(tabChanged(clearValue ? 'news' : 'search'));

    dispatch(
      updateFeedFilters({
        query: searchQuery,
        postTypes: POST_TYPES.all,
        mediaType: contentType,
      })
    );
  };

  const clearFiltersHandler = () => {
    setSearchQuery('');
    setContentType(0);
    dispatch(updateFeedFilters());
    dispatch(changeFeedConfig({ resultsTabVisible: false }));
    dispatch(tabChanged('news'));

    navigate('/feed');
  };

  useEffect(() => {
    return () => {
      dispatch(updateFeedFilters());
    };
  }, [dispatch]);

  return (
    <Sider>
      <S.Container>
        {/* QUERY */}
        <h1>{t('feed.home')}</h1>

        <SearchInput
          allowClear
          placeholder={t('feed.search')}
          value={searchQuery}
          onChange={queryPostsHandler}
          onSearch={searchPostsHandler}
          style={{ width: '100%', margin: '7px 0 16px' }}
        />

        <S.FilterWrapper>
          <S.SectionTitle style={{ fontWeight: 600 }}>{t('filters')}</S.SectionTitle>
          <Button type="link" onClick={clearFiltersHandler}>
            {t('common.clearFilters')}
          </Button>
        </S.FilterWrapper>

        <Select
          value={contentType}
          style={{ width: '100%', marginBottom: '2rem' }}
          onChange={contentChangeHandler}
        >
          <Select.Option value={0}>{t('feed.content.all_types')}</Select.Option>
          <Select.Option value={MEDIA_TYPES.image}>{t('feed.content.images')}</Select.Option>
          <Select.Option value={MEDIA_TYPES.video}>{t('feed.content.videos')}</Select.Option>
          <Select.Option value={MEDIA_TYPES.file}>{t('feed.content.files')}</Select.Option>
          {networkFunctions.polls && (
            <Select.Option value={MEDIA_TYPES.survey}>{t('common.survey')}</Select.Option>
          )}
        </Select>

        <Button type="primary" disabled={!searchQuery} onClick={searchClickHandler}>
          {t('search')}
        </Button>

        <S.AppContainer>
          <S.SectionTitle>{t('availableApp')}</S.SectionTitle>
          <div className="app">
            <Button
              type="link"
              href="https://play.google.com/store/apps/details?id=br.com.android.beeworkplus"
              target="_blank"
              style={{ marginLeft: -12 }}
            >
              <S.Icon>
                <FontAwesomeIcon icon={faGooglePlay} color="#757575" />
              </S.Icon>
              <S.TextButton>Android</S.TextButton>
            </Button>
          </div>
          <div className="app">
            <Button
              type="link"
              href="https://apps.apple.com/br/app/4bee-work-plus/id1510586849"
              target="_blank"
              style={{ marginLeft: -12 }}
            >
              <S.Icon>
                <FontAwesomeIcon icon={faApple} color="#757575" />
              </S.Icon>
              <S.TextButton>iOS</S.TextButton>
            </Button>
          </div>
        </S.AppContainer>
      </S.Container>
    </Sider>
  );
};

export default Sidebar;
