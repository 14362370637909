import { useDebugValue } from 'react';
import { useSelector } from 'react-redux';

import {
  getCurrentUserIds,
  getToken,
  selectCurrentUser,
  selectMasterAdmin,
} from 'store/selectors/authSelectors';

export default function useAuth() {
  const token = useSelector(getToken);
  const { networkId, userId } = useSelector(getCurrentUserIds);
  const user = useSelector(selectCurrentUser);
  const { isSystemMasterAdmin } = useSelector(selectMasterAdmin);

  // console.log('auth user: ', user);
  // console.log('auth networkId: ', networkId);

  useDebugValue(token, token => (token ? 'token is Ready' : 'token is not ready'));

  return {
    ...user,
    networkSettings: user.networkSettings!,
    networkFunctions: user.networkFunctions!,
    networkId,
    token: token,
    userId,
    isSystemMasterAdmin,
  };
}
